import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

class GoogleService {
    constructor() {
        if (GoogleService.instance) {
            return GoogleService.instance;
        }
        this.init();
        GoogleService.instance = this;
    }

    init() {
        GoogleAuth.initialize({
            clientId: '311303303907-vbvmr77dpr99ip7i55ffv4ak4mve4t30.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
        });
    }

    async login() {
        try {
            const response = await GoogleAuth.signIn();
            console.log("response: ", response);
            return response.authentication.idToken;
        } catch (err) {
            console.error('Erro ao fazer login com o Google:', err);
            return null;
        }
    }

    logout() {
        GoogleAuth.signOut();
    }
}

export default new GoogleService();
