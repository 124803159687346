// LikedSongsSection.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import HorizontalCardList from './HorizontalCardList';

const LikedSongsSection = ({ data }) => {
    return (
      <Box sx={{ padding: 2, marginBottom: -1 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
          Músicas curtidas
        </Typography>
        <HorizontalCardList data={data} />
      </Box>
    );
  };
  
  export default LikedSongsSection;