// SongInfo.js
import React from 'react';
import { Box, Typography, IconButton, Avatar } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';

const SongInfo = ({ song, liked, handleLikeClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 3,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={song.image_url}
          alt={song.name}
          variant="rounded"
          sx={{ width: 72, height: 72, marginRight: 2 }}
        />
        <Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {song.name}
          </Typography>
          <Link
            to={`/artist/${song.artist_id}`}
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              {song.artist_name}
            </Typography>
          </Link>
        </Box>
      </Box>
      <IconButton onClick={handleLikeClick}>
        {liked ? (
          <FavoriteIcon sx={{ color: '#A52422' }} />
        ) : (
          <FavoriteBorderIcon />
        )}
      </IconButton>
    </Box>
  );
};

export default SongInfo;
