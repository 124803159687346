import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, IconButton } from '@mui/material';
import { Instagram, Facebook, YouTube, Twitter, Tiktok } from '@mui/icons-material'; // Importar ícones das redes sociais
import { RiTiktokLine } from 'react-icons/ri';
import TopBar from '../common/TopBar';
import CardSection from '../common/CardSection';
import FooterNavbar from '../common/FooterNavbar';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  getFirestore,
  where,
} from 'firebase/firestore';
import { auth } from '../../firebase';
import LikedSongsSection from './utils/LikedSongsSection';
import AnnouncementsCarousel from './utils/AnnouncementsCarousel';

const Home = () => {
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    let isMounted = true;

    const fetchSections = async (userId) => {
      setIsLoading(true);
      try {
        // Busca as seções definidas no Firestore
        const sectionsRef = collection(db, 'home_sections');
        const sectionsSnapshot = await getDocs(sectionsRef);
        let sectionsData = sectionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordena as seções com base no campo 'order'
        sectionsData.sort((a, b) => a.order - b.order);

        const sectionsWithData = await Promise.all(
          sectionsData.map(async (section) => {
            const {
              collection: collectionName,
              order_by,
              order_direction,
              limit: sectionLimit,
              filters,
              item_type,
            } = section;

            let collectionRef = collection(db, collectionName);
            let q = query(collectionRef);

            if (filters && filters.length > 0) {
              filters.forEach((filter) => {
                q = query(q, where(filter.field, filter.operator, filter.value));
              });
            }

            if (order_by && order_direction) {
              q = query(q, orderBy(order_by, order_direction));
            }

            if (sectionLimit) {
              q = query(q, limit(sectionLimit));
            }

            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            return {
              ...section,
              data,
            };
          })
        );

        const songsRef = collection(db, 'songs');

        if (userId) {
          const recentSongsRef = collection(db, `users/${userId}/recent_songs`);
          const recentSongsQuery = query(
            recentSongsRef,
            orderBy('last_access_at', 'desc'),
            limit(10)
          );
          const recentSongsSnapshot = await getDocs(recentSongsQuery);

          const recentSongDocs = recentSongsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const recentSongIds = recentSongDocs.map((doc) => doc.id);

          if (recentSongIds.length > 0) {
            const chunkSize = 10;
            const chunks = [];
            for (let i = 0; i < recentSongIds.length; i += chunkSize) {
              chunks.push(recentSongIds.slice(i, i + chunkSize));
            }

            let songsData = [];
            for (const chunk of chunks) {
              const songsQuery = query(songsRef, where('__name__', 'in', chunk));
              const songsSnapshot = await getDocs(songsQuery);
              const chunkData = songsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              songsData = songsData.concat(chunkData);
            }

            const orderedSongsData = recentSongIds.map((id) =>
              songsData.find((song) => song.id === id)
            );

            const recentSongsSection = {
              id: 'recent_songs',
              title: 'Buscas recentes',
              type: 'square',
              item_type: 'song',
              data: orderedSongsData,
              order: 0,
            };

            sectionsWithData.unshift(recentSongsSection);
          }

          const likedSongsRef = collection(db, `users/${userId}/liked_songs`);
          const likedSongsSnapshot = await getDocs(likedSongsRef);

          if (!likedSongsSnapshot.empty) {
            const likedSongIds = likedSongsSnapshot.docs.map((doc) => doc.id);

            const chunkSize = 10;
            const chunks = [];
            for (let i = 0; i < likedSongIds.length; i += chunkSize) {
              chunks.push(likedSongIds.slice(i, i + chunkSize));
            }

            let likedSongsData = [];
            for (const chunk of chunks) {
              const likedSongsQuery = query(songsRef, where('__name__', 'in', chunk));
              const likedSongsDataSnapshot = await getDocs(likedSongsQuery);
              const chunkData = likedSongsDataSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              likedSongsData = likedSongsData.concat(chunkData);
            }

            const likedSongsSection = {
              id: 'liked_songs',
              title: 'Músicas curtidas',
              type: 'horizontal',
              item_type: 'song',
              data: likedSongsData,
              order: 1,
            };

            sectionsWithData.unshift(likedSongsSection);
          }
        }

        if (isMounted) {
          setSections(sectionsWithData);
        }
      } catch (error) {
        console.error('Error fetching home sections:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      const userId = user ? user.uid : null;
      fetchSections(userId);
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [db]);

  return (
    <Box sx={{ pb: 7 }}>
      <TopBar />

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}></Typography>
        </Box>
      ) : (
        <>
          <AnnouncementsCarousel />
          {sections.map((section) => {
            if (section.id === 'liked_songs') {
              return <LikedSongsSection key={section.id} data={section.data} />;
            }
            return (
              <CardSection
                key={section.id}
                title={section.title}
                type={section.type}
                data={section.data}
                itemType={section.item_type}
              />
            );
          })}
        </>
      )}

      {/* Nova seção de redes sociais */}
      <Box sx={{ mt: 4, p: 2, textAlign: 'left' }}>
        <Typography variant="body1" sx={{ color: 'grey' }}>
          Siga o Worship Cifras
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'left', mt: 1, gap: 2 }}>
          <IconButton
            component="a"
            href="https://www.tiktok.com/@worship.cifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <RiTiktokLine  />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.instagram.com/worshipcifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Instagram />
          </IconButton>          
          <IconButton
            component="a"
            href="https://youtube.com/@worshipcifras6458?si=A6quQanD54dQK44S"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <YouTube />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.facebook.com/worshipcifras"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Facebook />
          </IconButton>
          <IconButton
            component="a"
            href="https://x.com/CifrasWorship"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            <Twitter />
          </IconButton>
        </Box>
      </Box>

      <FooterNavbar />
    </Box>
  );
};

export default Home;
