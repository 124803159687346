// SongContent.js
import React from 'react';
import { Box } from '@mui/material';

const SongContent = ({ songContent }) => {
  return (
    <Box
      sx={{ backgroundColor: '#2f2f2f', padding: 2, borderRadius: 2 }}
    >
      <Box sx={{ whiteSpace: 'pre-line', color: '#fff' }}>
        {songContent || 'Conteúdo não encontrado.'}
      </Box>
    </Box>
  );
};

export default SongContent;
