// CardSingleItem.js
import React from 'react';
import { Box, Card, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CardSingleItem = ({ item, type, itemType }) => {
  const navigate = useNavigate();
  const size = type === 'round' ? 120 : 150;

  const handleClick = () => {
    if (itemType === 'song') {
      navigate(`/song/${item.id}`);
    } else if (itemType === 'artist') {
      navigate(`/artist/${item.id}`);
    }
  };

  return (
    <Box sx={{ minWidth: size, marginRight: 1.5, flexShrink: 0 }} onClick={handleClick}>
      <Card
        sx={{
          borderRadius: type === 'round' ? '50%' : 1,
          width: size,
          height: size,
          overflow: 'hidden',
          padding: 0,
          margin: 0,
        }}
        elevation={0}
      >
        <CardMedia
          component="img"
          image={item.image_url || '/path/to/default/image.png'} // Forneça uma imagem padrão
          alt={item.name}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
      </Card>
      <Typography
        variant="caption"
        sx={{
          textAlign: type === 'round' ? 'center' : 'left',
          display: 'block',
          marginTop: 1,
          marginLeft: 0,
          fontWeight: 'bold',
          maxWidth: size,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {item.name}
      </Typography>
      {itemType === 'song' && (
        <Typography
          variant="caption"
          sx={{
            textAlign: type === 'round' ? 'center' : 'left',
            display: 'block',
            marginLeft: 0,
            color: 'gray',
            maxWidth: size,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.artist_name}
        </Typography>
      )}
    </Box>
  );
};

export default CardSingleItem;
