import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate, useLocation } from 'react-router-dom';

const FooterNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Defina um array de itens de navegação
  const navigationItems = [
    { label: 'Home', icon: <HomeIcon />, path: '/' },
    { label: 'Buscar', icon: <SearchIcon />, path: '/search' },
    { label: 'Configurações', icon: <SettingsIcon />, path: '/settings' },
  ];

  // Encontre o índice da rota atual
  const currentTab = navigationItems.findIndex(
    (item) => item.path === location.pathname
  );

  const handleNavigation = (event, newValue) => {
    navigate(navigationItems[newValue].path);
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        value={currentTab !== -1 ? currentTab : 0}
        onChange={handleNavigation}
        sx={{ backgroundColor: '#171717' }} // Fundo mais escuro
      >
        {navigationItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.label}
            icon={item.icon}
            sx={{
              color: currentTab === index ? '#F5F5F5' : '#888', // Branco quase puro para selecionado, cinza claro para não selecionado
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default FooterNavbar;
