import { getAuth } from 'firebase/auth';
import emailjs from 'emailjs-com';

export const sendEmail = ({ message, to, cc, song_id, song_name, song_artist_id, song_artist_name }) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const templateParams = {
    from_name: user?.displayName || 'Usuário',
    from_email: user?.email,
    song_id,
    song_name,
    song_artist_id,
    song_artist_name,
    message,
    to,
    cc,
  };

  emailjs.send('service_9qtur0g', 'template_x8a5yxg', templateParams, 'd9YDIv4Ibavfpa8O9')
    .then((response) => {
      console.log('Email enviado com sucesso!', response.status, response.text);
    }, (error) => {
      console.log('Erro ao enviar email:', error);
    });
};
