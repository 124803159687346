// src/components/common/SearchField.js
import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchField = ({ currentRefinement, refine }) => (
  <TextField
    fullWidth
    variant="outlined"
    placeholder="O que você quer tocar hoje?"
    value={currentRefinement}
    onChange={(event) => refine(event.currentTarget.value)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="action" />
        </InputAdornment>
      ),
    }}
  />
);

export default connectSearchBox(SearchField);
