import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { collection, addDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Importando Firestore

const CreateSong = () => {
  // Valores padrão
  const defaultGenre = 'Worship'; // Valor padrão para o gênero
  const defaultArtistName = 'Gabriela Rocha'; // Valor padrão para o nome do artista

  const [name, setName] = useState('');
  const [genre, setGenre] = useState(defaultGenre); // Campo já preenchido com valor padrão
  const [artistName, setArtistName] = useState(defaultArtistName); // Campo já preenchido com valor padrão
  const [tone, setTone] = useState('');
  const [chords, setChords] = useState('');
  const [loading, setLoading] = useState(false);

  // Função para buscar o artista pelo nome
  const fetchArtistByName = async (artistName) => {
    const artistQuery = query(
      collection(firestore, 'artists'),
      where('name', '==', artistName)
    );
    const artistSnapshot = await getDocs(artistQuery);

    if (artistSnapshot.empty) {
      return null; // Artista não encontrado
    }

    const artistDoc = artistSnapshot.docs[0]; // Pegamos o primeiro resultado
    return { id: artistDoc.id, image_url: artistDoc.data().image_url };
  };

  // Função para tratar e formatar o campo de chords
  const formatChords = (chords) => {
    return chords.replace(/\n/g, '\\n'); // Substitui as quebras de linha por '\n'
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // 1. Buscar o artista pelo nome
      const artistData = await fetchArtistByName(artistName);
      if (!artistData) {
        alert('Artista não encontrado. Verifique o nome do artista.');
        setLoading(false);
        return;
      }

      // 2. Adicionar o documento principal na collection 'songs'
      const newSongRef = await addDoc(collection(firestore, 'songs'), {
        name,
        genre: genre || defaultGenre, // Aplica o valor padrão caso não esteja preenchido
        artist_name: artistName || defaultArtistName, // Aplica o valor padrão caso não esteja preenchido
        artist_id: artistData.id,
        image_url: artistData.image_url,
        likes_count: 0,
        searches_count: 0,
        favorites_count: 0,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      });

      // 3. Adicionar os dados na subcollection 'songs_content'
      await addDoc(collection(firestore, `songs/${newSongRef.id}/songs_content`), {
        chords: formatChords(chords),
        tone,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      });

      // 4. Adicionar os dados na subcollection 'songs_details'
      await addDoc(collection(firestore, `songs/${newSongRef.id}/songs_details`), {
        composer: '',
        producer: '',
        other: '',
        release: '',
        created: Timestamp.now(),
        updated: Timestamp.now(),
      });

      alert('Canção criada com sucesso!');
      setName('');
      setGenre(defaultGenre); // Reseta o campo para o valor padrão após a inserção
      setArtistName(defaultArtistName); // Reseta o campo para o valor padrão após a inserção
      setTone('');
      setChords('');
    } catch (error) {
      console.error('Erro ao criar a canção:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Adicionar Nova Canção
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome da Canção"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Gênero"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Nome do Artista"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Tom"
          value={tone}
          onChange={(e) => setTone(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Cifras"
          value={chords}
          onChange={(e) => setChords(e.target.value)}
          fullWidth
          multiline
          rows={4}
          required
          sx={{ marginBottom: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? 'Criando...' : 'Adicionar Canção'}
        </Button>
      </form>
    </Box>
  );
};

export default CreateSong;
