import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  initializeFirestore,
  persistentLocalCache,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCy7jvJVOi_3BSpf3znmYRu6dnZNrsKR0c",
  authDomain: "worship-cifras-v4bbg4.firebaseapp.com",
  projectId: "worship-cifras-v4bbg4",
  storageBucket: "worship-cifras-v4bbg4.appspot.com",
  messagingSenderId: "311303303907",
  appId: "1:311303303907:web:87ac76bf02e19de77a8bd6",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const storage = getStorage(app);

const firestore = initializeFirestore(app, { // usando cache para otimizar a persistencia e consulta de dados
  localCache: persistentLocalCache(),
});

export { auth, firestore, storage };