import { getAuth } from 'firebase/auth';
import emailjs from 'emailjs-com';

export const sendEmail = ({ subject, message, to, cc }) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const templateParams = {
    from_name: user?.displayName || 'Usuário',
    from_email: user?.email,
    subject,
    message,
    to,
    cc,
  };

  emailjs.send('service_9qtur0g', 'template_5l3pdza', templateParams, 'd9YDIv4Ibavfpa8O9')
    .then((response) => {
      console.log('Email enviado com sucesso!', response.status, response.text);
    }, (error) => {
      console.log('Erro ao enviar email:', error);
    });
};
