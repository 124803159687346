// Recommend.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import NavigationBar from '../common/NavigationBar';
import CardSection from '../common/CardSection';
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const Recommend = () => {
  const { tag } = useParams();
  const [recommendData, setRecommendData] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchRecommendData = async () => {
      try {
        // Criar uma consulta para obter o documento onde 'tag' == tag
        const recommendRef = collection(db, 'recommend_screen');
        const q = query(recommendRef, where('tag', '==', tag));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Supondo que a tag é única, pegamos o primeiro documento
          const doc = querySnapshot.docs[0];
          const recommendDocData = { id: doc.id, ...doc.data() };

          // Agora buscamos as seções dentro deste documento
          const sectionsRef = collection(db, `recommend_screen/${doc.id}/sections`);
          const sectionsQuery = query(sectionsRef, orderBy('order', 'asc'));
          const sectionsSnapshot = await getDocs(sectionsQuery);

          const sectionsData = await Promise.all(
            sectionsSnapshot.docs.map(async (sectionDoc) => {
              const sectionData = { id: sectionDoc.id, ...sectionDoc.data() };
              // Obter as músicas a partir dos IDs no campo 'songs'
              const songIds = sectionData.songs
                .split(',')
                .map((id) => id.trim());

              let songsData = [];
              const chunkSize = 10;

              for (let i = 0; i < songIds.length; i += chunkSize) {
                const chunk = songIds.slice(i, i + chunkSize);
                const songsRef = collection(db, 'songs');
                const songsQuery = query(
                  songsRef,
                  where('__name__', 'in', chunk)
                );
                const songsSnapshot = await getDocs(songsQuery);

                songsData = songsData.concat(
                  songsSnapshot.docs.map((songDoc) => ({
                    id: songDoc.id,
                    ...songDoc.data(),
                  }))
                );
              }

              // Ordenar as músicas na mesma ordem que os IDs fornecidos
              const orderedSongsData = songIds.map((id) =>
                songsData.find((song) => song.id === id)
              );

              return {
                ...sectionData,
                data: orderedSongsData,
              };
            })
          );

          setRecommendData({
            ...recommendDocData,
            sections: sectionsData,
          });
        } else {
          console.error('Nenhum documento encontrado para a tag fornecida.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados da recomendação:', error);
      }
    };

    fetchRecommendData();
  }, [tag, db]);

  if (!recommendData) {
    return <div>Carregando...</div>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <NavigationBar />
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {recommendData.tittle}
      </Typography>
      <Typography variant="h7" sx={{marginBottom: 3, color: "gray" }}>
        {recommendData.subtittle}
      </Typography>
      {recommendData.sections.map((section) => (
        <CardSection
          key={section.id}
          title={section.tittle}
          type="square" // ou outro tipo, conforme desejar
          data={section.data}
          itemType="song" // supondo que estamos listando músicas
        />
      ))}
    </Box>
  );
};

export default Recommend;
