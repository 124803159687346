// chordOptions.js
export const getChordOptions = (chordName) => {
    const chords = {
        C: {
            chord: [
              [1, 0],
              [2, 1],
              [3, 0],
              [4, 2],
              [5, 3],
              [6, 'x'],
            ],
          },
          Cm: {
            chord: [
              [1, 3],
              [2, 4],
              [3, 5],
              [4, 5],
              [5, 3],
              [6, 'x'],
            ],
          },
          C7: {
            chord: [
              [1, 0],
              [2, 1],
              [3, 3],
              [4, 2],
              [5, 3],
              [6, 'x'],
            ],
          },
          Cm7: {
            chord: [
              [1, 3],
              [2, 4],
              [3, 3],
              [4, 5],
              [5, 3],
              [6, 'x'],
            ],
          },
          Cmaj7: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 0],
              [4, 2],
              [5, 3],
              [6, 'x'],
            ],
          },
          "C#": {
            chord: [
              [1, 1],
              [2, 2],
              [3, 1],
              [4, 3],
              [5, 4],
              [6, 'x'],
            ],
          },
          "C#m": {
            chord: [
              [1, 4],
              [2, 5],
              [3, 6],
              [4, 6],
              [5, 4],
              [6, 'x'],
            ],
          },
          "C#7": {
            chord: [
              [1, 1],
              [2, 2],
              [3, 4],
              [4, 3],
              [5, 4],
              [6, 'x'],
            ],
          },
          "C#m7": {
            chord: [
              [1, 4],
              [2, 5],
              [3, 4],
              [4, 6],
              [5, 4],
              [6, 'x'],
            ],
          },
          "C#maj7": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 1],
              [4, 3],
              [5, 4],
              [6, 'x'],
            ],
          },
          Db: {
            chord: [
              [1, 'x'],
              [2, 6],
              [3, 6],
              [4, 6],
              [5, 4],
              [6, 'x'],
            ],
          },
          Dbm: {
            chord: [
              [1, 'x'],
              [2, 6],
              [3, 6],
              [4, 5],
              [5, 4],
              [6, 'x'],
            ],
          },
          Db7: {
            chord: [
              [1, 'x'],
              [2, 6],
              [3, 4],
              [4, 6],
              [5, 4],
              [6, 'x'],
            ],
          },
          Dbm7: {
            chord: [
              [1, 'x'],
              [2, 6],
              [3, 4],
              [4, 5],
              [5, 4],
              [6, 'x'],
            ],
          },
          Dbmaj7: {
            chord: [
              [1, 'x'],
              [2, 6],
              [3, 5],
              [4, 6],
              [5, 4],
              [6, 'x'],
            ],
          },
          D: {
            chord: [
              [1, 2],
              [2, 3],
              [3, 2],
              [4, 0],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          Dm: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 2],
              [4, 0],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          D7: {
            chord: [
              [1, 2],
              [2, 1],
              [3, 2],
              [4, 0],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          Dm7: {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 0],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          Dmaj7: {
            chord: [
              [1, 2],
              [2, 2],
              [3, 2],
              [4, 0],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          "D#": {
            chord: [
              [1, 3],
              [2, 4],
              [3, 3],
              [4, 1],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          "D#m": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 3],
              [4, 1],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          "D#7": {
            chord: [
              [1, 3],
              [2, 2],
              [3, 3],
              [4, 1],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          "D#m7": {
            chord: [
              [1, 2],
              [2, 2],
              [3, 3],
              [4, 1],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          "D#maj7": {
            chord: [
              [1, 3],
              [2, 3],
              [3, 3],
              [4, 1],
              [5, 'x'],
              [6, 'x'],
            ],
          },
          Eb: {
            chord: [
              [1, 3],
              [2, 4],
              [3, 3],
              [4, 5],
              [5, 6],
              [6, 'x'],
            ],
          },
          Ebm: {
            chord: [
              [1, 6],
              [2, 8],
              [3, 6],
              [4, 8],
              [5, 6],
              [6, 'x'],
            ],
          },
          Eb7: {
            chord: [
              [1, 6],
              [2, 8],
              [3, 6],
              [4, 8],
              [5, 6],
              [6, 'x'],
            ],
          },
          Ebm7: {
            chord: [
              [1, 6],
              [2, 8],
              [3, 6],
              [4, 8],
              [5, 6],
              [6, 'x'],
            ],
          },
          Ebmaj7: {
            chord: [
              [1, 6],
              [2, 8],
              [3, 7],
              [4, 8],
              [5, 6],
              [6, 'x'],
            ],
          },
          E: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 1],
              [4, 2],
              [5, 2],
              [6, 0],
            ],
          },
          Em: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 0],
              [4, 2],
              [5, 2],
              [6, 0],
            ],
          },
          E7: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 1],
              [4, 0],
              [5, 2],
              [6, 0],
            ],
          },
          Em7: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 0],
              [4, 0],
              [5, 2],
              [6, 0],
            ],
          },
          Emaj7: {
            chord: [
              [1, 0],
              [2, 0],
              [3, 1],
              [4, 1],
              [5, 2],
              [6, 0],
            ],
          },
          "E#": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 3],
              [5, 3],
              [6, 1],
            ],
          },
          "E#m": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          "E#7": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          "E#m7": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          "E#maj7": {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 2],
              [5, 3],
              [6, 1],
            ],
          },
          F: {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 3],
              [5, 3],
              [6, 1],
            ],
          },
          Fm: {
            chord: [
              [1, 1],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          F7: {
            chord: [
              [1, 1],
              [2, 1],
              [3, 2],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          Fm7: {
            chord: [
              [1, 1],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 1],
            ],
          },
          Fmaj7: {
            chord: [
              [1, 0],
              [2, 1],
              [3, 2],
              [4, 3],
              [5, 3],
              [6, 1],
            ],
          },
          "F#": {
            chord: [
              [1, 2],
              [2, 2],
              [3, 3],
              [4, 4],
              [5, 4],
              [6, 2],
            ],
          },
          "F#m": {
            chord: [
              [1, 2],
              [2, 2],
              [3, 2],
              [4, 2],
              [5, 4],
              [6, 2],
            ],
          },
          "F#7": {
            chord: [
              [1, 2],
              [2, 2],
              [3, 3],
              [4, 2],
              [5, 4],
              [6, 2],
            ],
          },
          "F#m7": {
            chord: [
              [1, 2],
              [2, 2],
              [3, 2],
              [4, 2],
              [5, 4],
              [6, 2],
            ],
          },
          "F#maj7": {
            chord: [
              [1, 1],
              [2, 2],
              [3, 3],
              [4, 4],
              [5, 4],
              [6, 2],
            ],
          },
          G: {
            chord: [
              [1, 3],
              [2, 0],
              [3, 0],
              [4, 0],
              [5, 2],
              [6, 3],
            ],
          },
          Gm: {
            chord: [
              [1, 3],
              [2, 3],
              [3, 3],
              [4, 5],
              [5, 5],
              [6, 3],
            ],
          },
          G7: {
            chord: [
              [1, 1],
              [2, 0],
              [3, 0],
              [4, 0],
              [5, 2],
              [6, 3],
            ],
          },
          Gm7: {
            chord: [
              [1, 3],
              [2, 3],
              [3, 3],
              [4, 3],
              [5, 2],
              [6, 3],
            ],
          },
          Gmaj7: {
            chord: [
              [1, 2],
              [2, 0],
              [3, 0],
              [4, 0],
              [5, 2],
              [6, 3],
            ],
          },
          "G#": {
            chord: [
              [1, 4],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 'x'],
            ],
          },
          "G#m": {
            chord: [
              [1, 4],
              [2, 4],
              [3, 4],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          "G#7": {
            chord: [
              [1, 4],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 'x'],
            ],
          },
          "G#m7": {
            chord: [
              [1, 4],
              [2, 4],
              [3, 4],
              [4, 4],
              [5, 6],
              [6, 'x'],
            ],
          },
          "G#maj7": {
            chord: [
              [1, 4],
              [2, 1],
              [3, 1],
              [4, 1],
              [5, 3],
              [6, 'x'],
            ],
          },
          Ab: {
            chord: [
              [1, 4],
              [2, 4],
              [3, 5],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          Abm: {
            chord: [
              [1, 4],
              [2, 4],
              [3, 4],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          Ab7: {
            chord: [
              [1, 4],
              [2, 4],
              [3, 5],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          Abm7: {
            chord: [
              [1, 4],
              [2, 4],
              [3, 4],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          Abmaj7: {
            chord: [
              [1, 4],
              [2, 4],
              [3, 5],
              [4, 6],
              [5, 6],
              [6, 'x'],
            ],
          },
          A: {
            chord: [
              [1, 0],
              [2, 2],
              [3, 2],
              [4, 2],
              [5, 0],
              [6, 'x'],
            ],
          },
          Am: {
            chord: [
              [1, 0],
              [2, 1],
              [3, 2],
              [4, 2],
              [5, 0],
              [6, 'x'],
            ],
          },
          A7: {
            chord: [
              [1, 0],
              [2, 2],
              [3, 0],
              [4, 2],
              [5, 0],
              [6, 'x'],
            ],
          },
          Am7: {
            chord: [
              [1, 0],
              [2, 1],
              [3, 0],
              [4, 2],
              [5, 0],
              [6, 'x'],
            ],
          },
          Amaj7: {
            chord: [
              [1, 0],
              [2, 2],
              [3, 1],
              [4, 2],
              [5, 0],
              [6, 'x'],
            ],
          },
          "A#": {
            chord: [
              [1, 1],
              [2, 3],
              [3, 3],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          "A#m": {
            chord: [
              [1, 1],
              [2, 2],
              [3, 1],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          "A#7": {
            chord: [
              [1, 1],
              [2, 3],
              [3, 1],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          "A#m7": {
            chord: [
              [1, 1],
              [2, 2],
              [3, 1],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          "A#maj7": {
            chord: [
              [1, 1],
              [2, 3],
              [3, 2],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          Bb: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 3],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          Bbm: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 3],
              [4, 1],
              [5, 1],
              [6, 'x'],
            ],
          },
          Bb7: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 1],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          Bbm7: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 1],
              [4, 1],
              [5, 1],
              [6, 'x'],
            ],
          },
          Bbmaj7: {
            chord: [
              [1, 1],
              [2, 3],
              [3, 2],
              [4, 3],
              [5, 1],
              [6, 'x'],
            ],
          },
          "Bb#": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 4],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          "Bb#m": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 4],
              [4, 2],
              [5, 2],
              [6, 'x'],
            ],
          },
          "Bb#7": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 2],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          "Bb#m7": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 2],
              [4, 2],
              [5, 2],
              [6, 'x'],
            ],
          },
          "Bb#maj7": {
            chord: [
              [1, 2],
              [2, 4],
              [3, 3],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          B: {
            chord: [
              [1, 2],
              [2, 4],
              [3, 4],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          Bm: {
            chord: [
              [1, 2],
              [2, 4],
              [3, 4],
              [4, 3],
              [5, 2],
              [6, 'x'],
            ],
          },
          B7: {
            chord: [
              [1, 2],
              [2, 4],
              [3, 2],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          Bm7: {
            chord: [
              [1, 2],
              [2, 4],
              [3, 2],
              [4, 3],
              [5, 2],
              [6, 'x'],
            ],
          },
          Bmaj7: {
            chord: [
              [1, 2],
              [2, 4],
              [3, 3],
              [4, 4],
              [5, 2],
              [6, 'x'],
            ],
          },
          "B#": {
            chord: [
              [1, 3],
              [2, 5],
              [3, 5],
              [4, 5],
              [5, 3],
              [6, 'x'],
            ],
          },
          "B#m": {
            chord: [
              [1, 3],
              [2, 5],
              [3, 5],
              [4, 4],
              [5, 3],
              [6, 'x'],
            ],
          },
          "B#7": {
            chord: [
              [1, 3],
              [2, 5],
              [3, 3],
              [4, 5],
              [5, 3],
              [6, 'x'],
            ],
          },
          "B#m7": {
            chord: [
              [1, 3],
              [2, 5],
              [3, 3],
              [4, 4],
              [5, 3],
              [6, 'x'],
            ],
          },
          "B#maj7": {
            chord: [
              [1, 3],
              [2, 5],
              [3, 4],
              [4, 5],
              [5, 3],
              [6, 'x'],
            ],
          },
    };
  
    return chords[chordName] || null;
  };
  