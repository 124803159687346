import React, { useEffect, useState } from 'react';
import { Box, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'; // Importar o auth do Firebase
import { onAuthStateChanged } from 'firebase/auth'; // Importar onAuthStateChanged
import LogoImage from '../../assets/images/logo/logo-light-beta.png';
import AvatarImage from '../../assets/images/profile/profile-default.png';

const TopBar = () => {
  const navigate = useNavigate();
  const [userPhoto, setUserPhoto] = useState(AvatarImage);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState(''); // Novo estado para armazenar o nome do usuário
  const [isEmailVerified, setIsEmailVerified] = useState(true); // Novo estado para e-mail verificado
  const [openDialog, setOpenDialog] = useState(false);

  const handleAvatarClick = () => {
    if (isAuthenticated && isEmailVerified) {
      navigate('/account'); // Usuário autenticado e com e-mail verificado, navega para '/account'
    } else {
      setOpenDialog(true); // Usuário não autenticado ou e-mail não verificado, abre o diálogo
    }
  };

  const handleLogin = () => {
    navigate('/signin'); // Navega para a página de login
    setOpenDialog(false); // Fecha o diálogo
  };

  const handleVerifyEmail = () => {
    navigate('/verify-email'); // Navega para a página de verificação de e-mail
    setOpenDialog(false); // Fecha o diálogo
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Fecha o diálogo
  };

  useEffect(() => {
    // Escuta as mudanças no estado de autenticação
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setIsEmailVerified(user.emailVerified); // Define o estado de verificação de e-mail

        // Extrai o primeiro nome do usuário
        const fullName = user.displayName || 'Usuário';
        const firstName = fullName.split(' ')[0];
        setUserName(firstName);

        if (user.photoURL) {
          setUserPhoto(user.photoURL);
        } else {
          setUserPhoto(AvatarImage);
        }
      } else {
        setIsAuthenticated(false);
        setIsEmailVerified(true); // Se não houver usuário, não precisa de verificação de e-mail
        setUserPhoto(AvatarImage);
      }
    });

    // Limpa o listener quando o componente for desmontado
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Avatar
          alt="Usuário"
          src={userPhoto}
          sx={{ width: 40, height: 40, cursor: 'pointer' }}
          onClick={handleAvatarClick}
        />
        {isAuthenticated ? (
          <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="body2" sx={{ color: 'gray', textAlign: 'left' }}>
              Olá,
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
              {userName}
            </Typography>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLogin}
            sx={{ ml: 2 }}
          >
            Entrar
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box component="img" src={LogoImage} alt="Logo" sx={{ height: 40 }} />
      </Box>

      {/* Diálogo para usuários não autenticados ou com e-mail não verificado */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <Typography>
            {isAuthenticated && !isEmailVerified
              ? 'Você precisa verificar o seu e-mail para acessar esta funcionalidade.'
              : 'Você precisa estar autenticado para acessar esta funcionalidade.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          {isAuthenticated && !isEmailVerified ? (
            <Button onClick={handleVerifyEmail} color="primary" variant="contained">
              Verificar e-mail
            </Button>
          ) : (
            <Button onClick={handleLogin} color="primary" variant="contained">
              Realizar Login
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopBar;
