// Artist.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, List, ListItem, ListItemText, Collapse, CircularProgress } from '@mui/material';
import { firestore } from '../../firebase';
import { doc, getDoc, collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import NavigationBar from '../common/NavigationBar';

const Artist = () => {
  const { id } = useParams(); // Obter o ID do artista a partir dos parâmetros da URL
  const navigate = useNavigate();

  // Estados
  const [artist, setArtist] = useState(null);
  const [songs, setSongs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false); // Carregamento das músicas
  const [artistLoading, setArtistLoading] = useState(true); // Carregamento dos dados do artista
  const [loadingMore, setLoadingMore] = useState(false);

  // useEffect para carregar os dados do artista
  useEffect(() => {
    const fetchArtistData = async () => {
      setArtistLoading(true); // Inicia o carregamento dos dados do artista
      try {
        const artistRef = doc(firestore, `artists/${id}`);
        const artistSnap = await getDoc(artistRef);
        if (artistSnap.exists()) {
          setArtist(artistSnap.data());
        } else {
          console.error('Artista não encontrado');
          navigate('/'); // Redirecionar se o artista não for encontrado
        }
      } catch (error) {
        console.error('Erro ao buscar dados do artista:', error);
      } finally {
        setArtistLoading(false); // Finaliza o carregamento dos dados do artista
      }
    };

    fetchArtistData();
  }, [id, navigate]);

  // useEffect para carregar as músicas mais acessadas
  useEffect(() => {
    if (artist) {
      fetchSongs();
    }
  }, [artist]);

  // Função para buscar as músicas
  const fetchSongs = async () => {
    setLoading(true); // Inicia o carregamento das músicas
    try {
      const songsRef = collection(firestore, 'songs');
      const songsQuery = query(
        songsRef,
        where('artist_id', '==', id),
        orderBy('searches_count', 'desc'),
        limit(10)
      );
      const songsSnapshot = await getDocs(songsQuery);
      const songsList = songsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setSongs(songsList);
      setLastVisible(songsSnapshot.docs[songsSnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Erro ao buscar músicas:', error);
    } finally {
      setLoading(false); // Finaliza o carregamento das músicas
    }
  };

  // Função para carregar mais músicas
  const loadMoreSongs = async () => {
    setLoadingMore(true);
    try {
      const songsRef = collection(firestore, 'songs');
      const songsQuery = query(
        songsRef,
        where('artist_id', '==', id),
        orderBy('searches_count', 'desc'),
        startAfter(lastVisible),
        limit(5)
      );
      const songsSnapshot = await getDocs(songsQuery);
      const moreSongs = songsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        added: true, // Marca os novos itens
      }));
      setSongs((prevSongs) => [...prevSongs, ...moreSongs]);
      setLastVisible(songsSnapshot.docs[songsSnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Erro ao carregar mais músicas:', error);
    }
    setLoadingMore(false);
  };

  // Função para ir para a página da música
  const handleSongClick = (songId) => {
    navigate(`/song/${songId}`);
  };

  // Renderização condicional com base no estado de carregamento dos dados do artista
  if (artistLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: 2 }}></Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* NavigationBar com seta de voltar */}
      <NavigationBar />

      {/* Banner do Artista */}
      <Box
        sx={{
          position: 'relative',
          borderRadius: '10px',
          overflow: 'hidden',
          marginTop: 2,
          marginBottom: 2,
          height: '200px', // Defina a altura desejada
        }}
      >
        <img
          src={artist.image_url}
          alt={artist.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'top', // Foca na parte superior da imagem
          }}
        />
        {/* Gradiente para legibilidade do nome */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '50%',
            background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.7))',
          }}
        />
        {/* Nome do Artista */}
        <Typography
          variant="h4"
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          {artist.name}
        </Typography>
      </Box>

      {/* Seção "Mais acessadas" */}
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Mais acessadas
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px', // Ajuste conforme necessário
          }}
        >
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}></Typography>
        </Box>
      ) : (
        <List>
          {songs.map((song, index) => (
            <Collapse
              in={true}
              timeout={500}
              key={song.id}
              appear={song.added ? true : false}
            >
              <ListItem button onClick={() => handleSongClick(song.id)}>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ color: 'gray', marginRight: 1 }}>
                        {index + 1}
                      </Typography>
                      <Typography>{song.name}</Typography>
                    </Box>
                  }
                />
              </ListItem>
            </Collapse>
          ))}
        </List>
      )}
      {/* Botão "Mostrar mais músicas" */}
      <Button
        variant="contained"
        color="primary"
        onClick={loadMoreSongs}
        disabled={loadingMore}
        sx={{ marginTop: 2 }}
      >
        {loadingMore ? 'Carregando...' : 'Mostrar mais músicas'}
      </Button>
    </Box>
  );
};

export default Artist;
