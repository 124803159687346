// NavigationBar.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

const NavigationBar = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 3,
        justifyContent: 'space-between',
      }}
    >
      {/* Seção Esquerda */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Voltar
        </Typography>
      </Box>

      {/* Ícone de Home no lado direito */}
      <IconButton onClick={handleHome}>
        <HomeIcon />
      </IconButton>
    </Box>
  );
};

export default NavigationBar;
