// ReportDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  TextareaAutosize
} from '@mui/material';
import { styled } from '@mui/material/styles';

const buttonStyle = {
  backgroundColor: '#A52422',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#FF5722',
  },
};

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '94%',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(1.5, 1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[700]}`,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  '&:focus': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
}));

const ReportDialog = ({
  openReportDialog,
  handleCloseReportDialog,
  reportMessage,
  setReportMessage,
  handleSendReport,
}) => {
  return (
    <Dialog
      open={openReportDialog}
      onClose={handleCloseReportDialog}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: '#1e1e1e',
          color: '#fff',
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle>Reportar Problema na Cifra</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth variant="outlined">
          <InputLabel shrink htmlFor="report-message">
            Descreva o problema
          </InputLabel>
          <StyledTextarea
            id="report-message"
            aria-label="Descreva o problema"
            minRows={5}
            maxRows={10}
            placeholder="Escreva detalhadamente o problema encontrado na cifra..."
            value={reportMessage}
            onChange={(e) => setReportMessage(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button sx={buttonStyle} onClick={handleSendReport}>
          Enviar
        </Button>
        <Button onClick={handleCloseReportDialog} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
