import React, { useState } from 'react';
import { Box } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import TopBar from '../common/TopBar';
import FooterNavbar from '../common/FooterNavbar';
import SearchField from './SearchField';
import SectionCards from '../common/SectionCards';
import Hit from './Hit';

const searchClient = algoliasearch('6QOJL3SBOW', 'f2448d5d2027753a6d0842aac18426ea');

const categories = [
  { title: 'Worships em alta', tag: 'worship' },
  { title: 'Santa Ceia', tag: 'santaceia' },
  { title: 'Animadas', tag: 'animadas' },
  { title: 'Trend Songs', tag: 'trend' },
  { title: 'As mais clássicas', tag: 'classicas' },
  { title: 'Acústicas', tag: 'acusticas' },
];

const Search = () => {
  const [query, setQuery] = useState('');

  return (
    <Box sx={{ pb: 7 }}>
      <TopBar />
      <InstantSearch
        searchClient={searchClient}
        indexName="songs"
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Box sx={{ paddingX: 2, marginY: 2 }}>
          <SearchField />
        </Box>
        <Configure hitsPerPage={10} />
        {query.length > 0 && ( // Exibe resultados somente se o campo de busca não estiver vazio
          <Box 
            component="ul" 
            sx={{  
              padding: 0, 
              margin: 0 
            }}
          >
            <Hits hitComponent={Hit} />
          </Box>
        )}
      </InstantSearch>
      <SectionCards title="Que tal algumas sugestões" data={categories} />
      <FooterNavbar />
    </Box>
  );
};

export default Search;
