import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { collection, addDoc, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { firestore, storage } from '../../firebase'; // Importando Firestore e Storage

const CreateArtist = () => {
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  // Função para tratar e comprimir a imagem
  const resizeImage = (imageBlob) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        imageBlob,
        500, // largura máxima
        500, // altura máxima
        'JPEG', // formato de saída
        80, // qualidade da imagem
        0, // rotação
        (uri) => resolve(uri), // retorno da imagem comprimida
        'blob' // tipo de retorno
      );
    });

  // Função para baixar a imagem de uma URL
  const downloadImageFromUrl = async (url) => {
    const response = await axios.get(url, { responseType: 'blob' });
    return response.data;
  };

  // Função para fazer upload da imagem tratada
  const uploadImageToStorage = async (id, imageBlob) => {
    const imageRef = ref(storage, `artists/${id}.jpg`);
    await uploadBytes(imageRef, imageBlob);
    return getDownloadURL(imageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let newArtistRef;

    try {
      // Adiciona o artista sem a imagem primeiro para gerar o ID
      newArtistRef = await addDoc(collection(firestore, 'artists'), {
        name,
        image_url: '', // temporário, atualizado após o upload
        searches_count: 0,
        favorites_count: 0,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      });

      try {
        const imageBlob = await downloadImageFromUrl(imageUrl); // Baixa a imagem da URL
        const resizedImageBlob = await resizeImage(imageBlob); // Redimensiona a imagem
        const finalImageUrl = await uploadImageToStorage(newArtistRef.id, resizedImageBlob); // Faz o upload para o Storage

        // Atualiza o campo "image_url" com o link gerado
        const artistDocRef = doc(firestore, 'artists', newArtistRef.id); // Cria a referência ao documento do artista
        await updateDoc(artistDocRef, {
          image_url: finalImageUrl,
          updated: Timestamp.now(),
        });

        alert('Artista criado com sucesso!');
      } catch (imageError) {
        console.error('Erro ao processar a imagem:', imageError);
        // Se falhar no download ou upload da imagem, o documento será excluído
        await deleteDoc(doc(firestore, 'artists', newArtistRef.id));
        alert('Erro ao processar a imagem. O registro foi removido.');
      }

    } catch (error) {
      console.error('Erro ao criar artista:', error);
    } finally {
      setName('');
      setImageUrl('');
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Adicionar Novo Artista
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome do Artista"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="URL da Imagem"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? 'Criando...' : 'Adicionar Artista'}
        </Button>
      </form>
    </Box>
  );
};

export default CreateArtist;
