// HorizontalCardList.js
import React from 'react';
import { Box } from '@mui/material';
import LikedSongCard from './LikedSongCard';

const HorizontalCardList = ({ data }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateRows: 'repeat(2, 70px)', // Define a altura de cada linha
        gridAutoColumns: '200px', // Define a largura de cada coluna
        gap: 2,
        overflowX: 'auto',
        paddingBottom: 2,
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {data.map((item) => (
        <LikedSongCard key={item.id} song={item} />
      ))}
    </Box>
  );
};

export default HorizontalCardList;
