// TabSelector.js
import React from 'react';
import { Box, Chip } from '@mui/material';

const TabSelector = ({ selectedTab, handleTabClick }) => {
  const tabs = ['Cifra', 'Letra', 'Detalhes'];

  return (
    <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
      {tabs.map((tab) => (
        <Chip
          key={tab}
          label={tab}
          clickable
          color={selectedTab === tab ? 'primary' : 'default'}
          onClick={() => handleTabClick(tab)}
          sx={{
            backgroundColor: selectedTab === tab ? '#A52422' : 'default',
            color: '#fff',
            border: 'none',
            fontSize: '1rem',
            padding: '5px 10px',
            '&:hover': {
              backgroundColor: selectedTab === tab ? '#A52422' : 'default',
            },
            '&:focus': {
              outline: 'none',
            },
          }}
        />
      ))}
    </Box>
  );
};

export default TabSelector;
