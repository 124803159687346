// ChordDiagram.js
import React, { useEffect, useRef } from 'react';
import { draw } from 'vexchords';
import { getChordOptions } from '../functions/chordOptions'; // Ajuste o caminho conforme sua estrutura

const ChordDiagram = ({ chordName }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.innerHTML = '';

    const chordData = getChordOptions(chordName);

    const options = {
      defaultColor: '#fff', // Cor do texto e traços
      strokeColor: '#fff',
      textColor: '#fff',
      fretLabelColor: '#fff',
      stringColor: '#fff',
      fretColor: '#fff',
      bgColor: '#222', // Cor de fundo do diagrama
    };

    if (chordData) {
      draw(containerRef.current, chordData, options);
    } else {
      // Desenha um diagrama em branco para acordes não encontrados
      draw(
        containerRef.current,
        {
          chord: [
            [1, 'x'],
            [2, 'x'],
            [3, 'x'],
            [4, 'x'],
            [5, 'x'],
            [6, 'x'],
          ],
        },
        options
      );
    }
  }, [chordName]);

  return (
    <div style={{ textAlign: 'center', color: '#fff' }}>
      {/* Nome do acorde em vermelho acima do diagrama */}
      <div style={{ color: '#A52422'}}>{chordName}</div>
      <div ref={containerRef}></div>
      {/* Mensagem para acordes não encontrados */}
      {!getChordOptions(chordName) && (
        <div style={{ color: 'gray', fontSize: '0.8em' }}>
          Acorde não cadastrado
        </div>
      )}
    </div>
  );
};

export default ChordDiagram;
