// AuthDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';

const AuthDialog = ({
  openDialog,
  handleCloseDialog,
  handleLogin,
  handleVerifyEmail, // Adicionado para redirecionar para verificação de e-mail
  isAuthenticated,
  isEmailVerified,
}) => {
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Aviso</DialogTitle>
      <DialogContent>
        <Typography>
          {isAuthenticated && !isEmailVerified
            ? 'Você precisa verificar o seu e-mail para curtir uma música.'
            : 'Você precisa estar autenticado para curtir uma música.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancelar
        </Button>
        {isAuthenticated && !isEmailVerified ? (
          <Button
            onClick={handleVerifyEmail}
            color="primary"
            variant="contained"
          >
            Verificar e-mail
          </Button>
        ) : (
          <Button
            onClick={handleLogin}
            color="primary"
            variant="contained"
          >
            Realizar Login
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AuthDialog;
