// AppTheme.js
import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { inputsCustomizations } from './customizations/inputs';
import { dataDisplayCustomizations } from './customizations/dataDisplay';
import { feedbackCustomizations } from './customizations/feedback';
import { navigationCustomizations } from './customizations/navigation';
import { surfacesCustomizations } from './customizations/surfaces';
import { shadows, shape } from './themePrimitives'; // Removi 'colorSchemes' já que não será usado

function AppTheme({ children, disableCustomTheme, themeComponents }) {
  const theme = React.useMemo(() => {
    return disableCustomTheme
      ? {}
      : createTheme({
          cssVarPrefix: 'template',
          palette: {
            mode: 'dark', // Força o modo escuro
            primary: {
              main: '#A52422',
            },
            secondary: {
              main: '#FF5722',
            },
            background: {
              default: '#1e1e1e',
              paper: '#2f2f2f',
            },
            text: {
              primary: '#ffffff',
              secondary: '#aaaaaa',
            },
          },
          typography: {
            fontFamily:
              '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", ' +
              '"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
          shadows,
          shape,
          components: {
            ...inputsCustomizations,
            ...dataDisplayCustomizations,
            ...feedbackCustomizations,
            ...navigationCustomizations,
            ...surfacesCustomizations,
            ...themeComponents,
            MuiBottomNavigationAction: {
              styleOverrides: {
                root: {
                  '&.Mui-selected': {
                    color: '#E0E0E0',
                  },
                },
              },
            },
          },
        });
  }, [disableCustomTheme, themeComponents]);

  if (disableCustomTheme) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

AppTheme.propTypes = {
  children: PropTypes.node,
  disableCustomTheme: PropTypes.bool,
  themeComponents: PropTypes.object,
};

export default AppTheme;
