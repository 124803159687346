// LikedSongCard.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LikedSongCard = ({ song }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/song/${song.id}`);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#2f2f2f',
        borderRadius: 2,
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex',
      }}
    >
      <Box
        component="img"
        src={song.image_url}
        alt={song.name}
        sx={{
          width: '40%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          padding: 1,
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle2"
          color="textSecondary"
          sx={{
            fontSize: '0.8rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {song.artist_name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {song.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default LikedSongCard;
