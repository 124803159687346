import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, Link } from '@mui/material';

// Dados de exemplo para anúncios
const announcements = [
  {
    id: 1,
    title: 'Lançou!',
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/worship-cifras-v4bbg4.appspot.com/o/home%2F1_lancou.jpg?alt=media&token=9ffd1318-17be-464c-b859-5c992ccc6eef', 
    description: 'A Open Beta do Worship Cifras está no ar! Crie sua conta e comece a usar todas as funcionalidades agora mesmo. Nesta temporada, alguns erros podem ocorrer, mas contamos com você para nos reportar e criarmos uma comunidade incrível e colaborativa.',
},
  {
    id: 2,
    title: 'Estamos no Tiktok',
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/worship-cifras-v4bbg4.appspot.com/o/home%2F2_tiktok.jpg?alt=media&token=122a9216-de87-4740-b4de-8b8389b3c820', 
    description: 'Siga a gente para ficar por dentro de tudo o que esta rolando.',
    link: 'https://www.tiktok.com/@worship.cifras',
  },
  {
    id: 3,
    title: 'Estamos no Instagram',
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/worship-cifras-v4bbg4.appspot.com/o/home%2F3_instagram.jpg?alt=media&token=6a6cbac7-5aa0-4534-8d7b-f1e90dd1f9f5', 
    description: 'Siga a gente para ficar por dentro de tudo o que esta rolando.',
    link: 'https://www.tiktok.com/@worship.cifras',
  },
];

const AnnouncementsCarousel = () => {
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const handleOpenDialog = (announcement) => {
    setSelectedAnnouncement(announcement);
  };

  const handleCloseDialog = () => {
    setSelectedAnnouncement(null);
  };

  return (
    <Box sx={{ mt: 4, p: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
        Novidades
      </Typography>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          gap: 2,
          pb: 2,
          scrollSnapType: 'x mandatory',
        }}
      >
        {announcements.map((announcement) => (
          <Box
            key={announcement.id}
            sx={{
              minWidth: 300,
              height: 300,
              borderRadius: 4,
              overflow: 'hidden',
              cursor: 'pointer',
              flexShrink: 0,
              border: '1px solid #222',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              scrollSnapAlign: 'start',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
            onClick={() => handleOpenDialog(announcement)}
          >
            <img
              src={announcement.imageUrl}
              alt={announcement.title}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        ))}
      </Box>

      <Dialog open={!!selectedAnnouncement} onClose={handleCloseDialog}>
        {selectedAnnouncement && (
          <>
            <DialogTitle>{selectedAnnouncement.title}</DialogTitle>
            <DialogContent>
              <Typography>{selectedAnnouncement.description}</Typography>
              {selectedAnnouncement.link && (
                <Typography sx={{ mt: 2 }}>
                  <Link href={selectedAnnouncement.link} target="_blank" rel="noopener">
                    Para saber mais, clique aqui.
                  </Link>.
                </Typography>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default AnnouncementsCarousel;