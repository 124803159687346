import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { sendEmailVerification } from 'firebase/auth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const EmailVerification = () => {
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (auth.currentUser) {
        await auth.currentUser.reload();
        if (auth.currentUser.emailVerified) {
          setSnackbarMessage('Email verificado com sucesso! Você está pronto para elevar o seu nível, seja bem-vindo.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          navigate('/');
        }
      }
    }, 5000); // Verifica a cada 5 segundos

    return () => clearInterval(interval);
  }, [navigate]);

  const handleResendEmail = async () => {
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        setSnackbarMessage('Email de verificação reenviado.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Nenhum usuário autenticado.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Erro ao reenviar email de verificação:', error);
      setSnackbarMessage('Ocorreu um erro ao reenviar o email de verificação.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleContinue = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'left', mb: 2 }}>
        Verifique seu email
      </Typography>
      <Box
        sx={{
          backgroundColor: '#333',
          p: 2,
          borderRadius: 2,
          mb: 3,
          width: '100%',
        }}
      >
        <Typography variant="body1">
          Um email de verificação foi enviado para o seu endereço de email.
          Por favor, verifique sua caixa de entrada e siga as instruções para confirmar sua conta.
          Esta etapa é importante para garantir a segurança da sua conta e para você conseguir aproveitar todos os recursos do Worship Cifras.
        </Typography>
      </Box>
      <Button variant="contained" onClick={handleResendEmail} sx={{ mb: 2 }}>
        Reenviar Email de Verificação
      </Button>
      <Button variant="outlined" onClick={handleContinue}>
        Continuar para a Página Inicial
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EmailVerification;
