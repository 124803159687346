import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { Highlight } from 'react-instantsearch-dom';
import { useNavigate } from 'react-router-dom';

const Hit = ({ hit }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Redireciona para a página de cifra, passando o ID da música
    navigate(`/song/${hit.objectID}`);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        gap: 2,
        cursor: 'pointer', // Muda o cursor para indicar que o item é clicável
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Adiciona efeito de hover
        },
      }}
    >
      {/* Imagem da música */}
      <Avatar
        src={hit.image_url}
        alt={hit.name}
        variant="rounded"
        sx={{ width: 56, height: 56 }}
      />

      {/* Texto com nome da música e nome do artista */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          <Highlight attribute="name" hit={hit} />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {hit.artist_name}
        </Typography>
      </Box>
    </Box>
  );
};

export default Hit;
