// YouTubePlayer.js
import React from 'react';
import YouTube from 'react-youtube';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, useMediaQuery } from '@mui/material';

const YouTubePlayer = ({ videoId, onClose }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const playerHeight = isMobile ? 250 : 400;

  const opts = {
    width: '100%',
    height: playerHeight,
    playerVars: {
      autoplay: 1,
      controls: 1,
      modestbranding: 1,
      rel: 0,
      fs: 1,
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#000',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        marginBottom: 2,
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          backgroundColor: '#A52422',
          '&:hover': {
            backgroundColor: '#222',
          },
          zIndex: 1,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <YouTube videoId={videoId} opts={opts} />
    </Box>
  );
};

export default YouTubePlayer;
